import React, { useState } from 'react';
import { Button } from "../../../components";
import i18n from '../../../i18n';
import { SearchResultI } from "../../../components/ComponentInterface";
import { getDobYear } from "../../../utils";
import { ReactComponent as Close } from '../../../asset/btn-compare_open.svg';
import { ReactComponent as Open } from '../../../asset/btn-compare_close.svg';
import SearchItemDetails from "./SearchItemDetails";
import { currentAccountStatus, currentVerificationStatus } from "../../../utils/SearchPage";

const SearchResultTable = ({ data, emptyMessage, searchingError, emptyBtnAction, validationBrokerData }: { data: Array<SearchResultI>, emptyMessage?: string, searchingError?: boolean, emptyBtnAction?: () => void, validationBrokerData?: any }) => {
    const [expandedRow, setExpandedRow] = useState<any>({isOpen: false, data: {}});
    const handleViewDetails = (item?: any) => {
        setExpandedRow({isOpen: true, data: item});
    }
    
    const handleCloseDetails = (item?: any) => {
        if (expandedRow?.isOpen && (item?.user_id !== expandedRow?.data?.user_id)) { 
            setExpandedRow({isOpen: true, data: item});
        }

        setExpandedRow({isOpen: false, data: {}});
    }

    const handleAccountStatus = (accountStatus?: string) => {
        const { status, className } = currentAccountStatus(accountStatus?.toUpperCase());
        
        return <td data-testid={"account-status"} className='nowrap'><p className={`status ${className}`}>{status}</p></td>;
    }

    const handleVerificationStatus = (verificationStatus?: string) => {
        const { status, className } = currentVerificationStatus(verificationStatus?.toUpperCase());
       
        return <p className={`status ${className}`}>{status}</p>;
    }

    const handleFirstLastNameDisplay = (firstName?: string, lastName?: string) => {
        if (firstName && lastName) {
            return `${firstName}, ${lastName}`;
        } else if (firstName && !lastName) {
             return firstName;
        } else if (!firstName && lastName) {
            return lastName;
        } else {
            return  "--"
       }
    }
   
    return (
        <>
            <div className='search-table'>
                <table data-testid={"results-table"}>
                    <thead>
                        <tr>
                            <th className="nowrap"><p>{`${i18n.t('SearchPage.SearchLabels.id_card_number')}`}</p></th>
                            <th className="nowrap"><p>{`${i18n.t('SearchPage.SearchLabels.email')}`}</p></th>
                            <th className="nowrap"><p>{`${i18n.t('SearchPage.SearchLabels.name')}`}</p></th>
                            <th className="nowrap"><p>{`${i18n.t('SearchPage.SearchLabels.year_of_birth')}`}</p></th>
                            <th className="nowrap"><p>{`${i18n.t('SearchPage.SearchLabels.account_status')}`}</p></th>
                            <th className="nowrap"><p>{`${i18n.t('SearchPage.SearchLabels.verification_status')}`}</p></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.length
                            ?
                            <>
                                {
                                    data.map((item) => (
                                        <React.Fragment key={item?.user_id}>
                                            <tr >
                                                <td data-testid={"id-card-num"}>{item?.id_card_number || "--"}</td>
                                                <td data-testid={"email"}>{item?.email || "--"}</td>
                                                <td data-testid={"name"}>{handleFirstLastNameDisplay(item?.last_name, item?.first_name)}</td>
                                                <td data-testid={"dob"}>{(item?.birth_date && getDobYear(item?.birth_date)) ||  "--"}</td>
                                                {handleAccountStatus(item?.account_status)}
                                                <td className='last-row' data-testid={"verification-status"}>
                                                    {
                                                        handleVerificationStatus(item?.verification_status)
                                                    }

                                                    { 
                                                        (expandedRow?.isOpen && (item?.user_id === expandedRow?.data?.user_id))
                                                        ? 
                                                        <button onClick={() => handleCloseDetails(item)} data-testid={"dropdown"}><Close /></button>
                                                        : 
                                                        <button onClick={() => handleViewDetails(item)} data-testid={"dropdown"}><Open /></button>
                                                    }
                                                </td>
                                            </tr>

                                            {
                                                (expandedRow?.isOpen && (item?.user_id === expandedRow?.data?.user_id))
                                                ?
                                                <tr className='search-details-row'>
                                                    <td colSpan={7}>
                                                        <SearchItemDetails 
                                                            searchData={expandedRow?.data} 
                                                            validationBrokerData={validationBrokerData}
                                                        />
                                                    </td>
                                                </tr>
                                                :
                                                null
                                            }
                                        </React.Fragment>
                                    ))
                                }
                            </>
                            :
                            <tr className={searchingError ? "hide-row": ""}><td><div className='empty-row-column' /></td></tr>
                        }
                    </tbody>
                </table>
            </div>
            
            {/* Empty Error */}
            {
                (searchingError && !data.length)
                ?
                <>
                    <p className="empty-list" data-testid={"no-results"}>{emptyMessage}</p>
                    <Button
                        text={i18n.t("SearchPage.create-account-button")}
                        onClick={emptyBtnAction}
                        className='create-account-btn'
                        dataTestId={"button-create-acc-start-verification"}
                    />
                </>
                :
                null
            }
        </>
    )
}

export default SearchResultTable;
