
const API_ERROR = "API_ERROR";
const SESSION_TIMEOUT = "SESSION_TIMEOUT";
const PENDING_REVIEW = "PENDING_REVIEW";
const OAUTH_PROVIDERS_ERROR = "OAUTH_PROVIDERS_ERROR";
const VERIFICATION_CODE_EXPIRED = "VERIFICATION_CODE_EXPIRED";

export {
    API_ERROR,
    SESSION_TIMEOUT,
    PENDING_REVIEW,
    OAUTH_PROVIDERS_ERROR,
    VERIFICATION_CODE_EXPIRED
}