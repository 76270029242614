import React, { useState, useEffect } from 'react';
import { Spinner } from "../../../components";
import i18n from '../../../i18n';
import { useContext } from "../../../context";
import { updateError } from "../../../context/actions/ConfigActions";
import { SESSION_TIMEOUT, API_ERROR } from "../../../utils/Constants";
import { useTriggeredGetPersonDetails } from "../../../services/AdminServices";
import AutoWidthInput from "../Components/AutoWidthInput";
import { updatedDataFromValidator, maskingFields } from "../../../utils/SearchPage";
import { removeDashSpaceSpecialChar } from "../../../utils";

const SearchItemDetails = ({ searchData, validationBrokerData }: { searchData?: any, validationBrokerData?: any }) => {
    const { state: { error }, dispatch } = useContext();
    const [verificationDetails, setVerificationDetails] = useState<any>({});

    const { useGetPersonDetails: getPersonDetails, isLoading } = useTriggeredGetPersonDetails(searchData?.user_id);
    
    // Update Data from Validator
    useEffect(() => {
        const dataFromValidator = () => {
            const result = updatedDataFromValidator(verificationDetails, validationBrokerData);
            
            setVerificationDetails({...result});
        }

        if (Object.keys(validationBrokerData).length) {
            dataFromValidator()
        }

    // eslint-disable-next-line 
    }, [validationBrokerData])

    useEffect(() => { 
        // GEt DEtails
        const getDetails = async () => {
            if (!error.hasError) {
                const { response, error } = await getPersonDetails();
    
                if (response.isSuccess) {
                    setVerificationDetails(response?.data);
                } 

                if (Object.keys(error).length) {
                    if (error.isUnauthorizedError || error.isForbiddenError) {
                        dispatch(updateError({ hasError: true, type: SESSION_TIMEOUT }));
                    } else {
                        dispatch(updateError({ hasError: true, type: API_ERROR }));
                    }
                }
            };  
        }

        getDetails();
        
        // eslint-disable-next-line
    }, [error.hasError]);

    const handleValueSanitization = (key: string, value: string) => {
        if (value !== null && value !== "null" && value !== "") {
            if (key === "postal_code") {
               return removeDashSpaceSpecialChar(value)
            }

            return value;
        } else {
           return "--"
        }
    }
   
    return (
        <div className='search-Details'>
            {
                isLoading
                ?
                <Spinner dark />
                :
                <>
                    {
                        Object.keys(verificationDetails).length
                        ?
                        <div className="container">
                            <div className='content'>
                                <table className="details-table">
                                    <thead>
                                        <tr>
                                            <th>{i18n.t("SearchPage.profile-information")}</th>
                                            <th></th>
                                            <th></th>
                                            <th>{i18n.t("SearchPage.health-card-information")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td data-testid={"first_name"}>
                                                <div className="item">
                                                    <strong>{i18n.t("first_name")}</strong>
                                                    <AutoWidthInput
                                                        mask={maskingFields("")}
                                                        initialValue={handleValueSanitization("first_name", String(verificationDetails?.first_name))}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </td>
                                            <td data-testid={"address_line_2"}>
                                                <div className="item">
                                                    <strong>{i18n.t("address_line_2")}</strong>
                                                    <AutoWidthInput
                                                        mask={maskingFields("")}
                                                        initialValue={handleValueSanitization("address_line_2", String(verificationDetails?.address_line_2))}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </td>
                                            <td data-testid={"id_card_number"}>
                                                <div className="item">
                                                    <strong>{i18n.t("id_card_number")}</strong>
                                                    <AutoWidthInput
                                                        mask={maskingFields("")}
                                                        initialValue={handleValueSanitization("id_card_number", String(verificationDetails?.id_card_number))}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </td>
                                            <td data-testid={"health_card_number"}>
                                                <div className="item">
                                                    <strong>{i18n.t("health_card_number")}</strong>
                                                    <AutoWidthInput
                                                        mask={maskingFields("")}
                                                        initialValue={handleValueSanitization("health_card_number", String(verificationDetails?.health_card_number))}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td data-testid={"middle_name"}>
                                                <div className="item">
                                                    <strong>{i18n.t("middle_name")}</strong>
                                                    <AutoWidthInput
                                                        mask={maskingFields("")}
                                                        initialValue={handleValueSanitization("middle_name", String(verificationDetails?.middle_name))}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </td>
                                            <td data-testid={"city"}>
                                                <div className="item">
                                                    <strong>{i18n.t("city")}</strong>
                                                    <AutoWidthInput
                                                        mask={maskingFields("")}
                                                        initialValue={handleValueSanitization("city", String(verificationDetails?.city))}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </td>
                                            <td data-testid={"id_card_expiry"}>
                                                <div className="item">
                                                    <strong>{i18n.t("id_card_expiry")}</strong>
                                                    <AutoWidthInput
                                                        mask={verificationDetails?.id_card_expiry ? maskingFields("id_card_expiry") : ""}
                                                        initialValue={handleValueSanitization("id_card_expiry", String(verificationDetails?.id_card_expiry))}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </td>
                                            <td data-testid={"health_card_expiry"}>
                                                <div className="item">
                                                    <strong>{i18n.t("health_card_expiry")}</strong>
                                                    <AutoWidthInput
                                                        mask={verificationDetails?.health_card_expiry ? maskingFields("health_card_expiry") : ""}
                                                        initialValue={handleValueSanitization("health_card_expiry", String(verificationDetails?.health_card_expiry))}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td data-testid={"last_name"}>
                                                <div className="item">
                                                    <strong>{i18n.t("last_name")}</strong>
                                                    <AutoWidthInput
                                                        mask={maskingFields("")}
                                                        initialValue={handleValueSanitization("last_name", String(verificationDetails?.last_name))}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </td>
                                            <td data-testid={"postal_code"}>
                                                <div className="item">
                                                    <strong>{i18n.t("postal_code")}</strong>
                                                    <AutoWidthInput
                                                        mask={verificationDetails?.postal_code ? maskingFields("postal_code") : ""}
                                                        initialValue={handleValueSanitization("postal_code", String(verificationDetails?.postal_code))}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </td>
                                            <td />
                                            <td />
                                        </tr>

                                        <tr>
                                            <td data-testid={"birth_date"}>
                                                <div className="item">
                                                    <strong>{i18n.t("birth_date")}</strong>
                                                    <AutoWidthInput
                                                        mask={verificationDetails?.birth_date ? maskingFields("birth_date") : ""}
                                                        initialValue={handleValueSanitization("birth_date", String(verificationDetails?.birth_date))}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </td>
                                            <td data-testid={"region"}>
                                                <div className="item">
                                                    <strong>{i18n.t("region")}</strong>
                                                    <AutoWidthInput
                                                        mask={maskingFields("")}
                                                        initialValue={handleValueSanitization("region", String(verificationDetails?.region))}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </td>
                                            <td />
                                            <td />
                                        </tr>

                                        <tr>
                                            <td data-testid={"address_line_1"}>
                                                <div className="item">
                                                    <strong>{i18n.t("address_line_1")}</strong>
                                                    <AutoWidthInput
                                                        mask={maskingFields("")}
                                                        initialValue={handleValueSanitization("address_line_1", String(verificationDetails?.address_line_1))}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </td>
                                            <td data-testid={"country"}>
                                                <div className="item">
                                                    <strong>{i18n.t("country")}</strong>
                                                    <AutoWidthInput
                                                        mask={maskingFields("")}
                                                        initialValue={handleValueSanitization("country", String(verificationDetails?.country))}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </td>
                                            <td />
                                            <td />
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        :
                        <p className="no-details-error">{i18n.t("SearchPage.no-details-error")}</p>
                    }
                </>
            }

        </div>
    )
}

export default SearchItemDetails;
