import React, { useEffect, useState } from 'react';
import {
    Button,
    ModalComp
} from '.';
import { LayoutI } from './ComponentInterface';
import i18n from '../i18n';
import parse from 'html-react-parser';
import { useHistory } from "react-router-dom";
import { useContext } from '../context';
import { 
    API_ERROR, 
    SESSION_TIMEOUT,
    VERIFICATION_CODE_EXPIRED
} from "../utils/Constants";
import { updateError, updateProfile } from '../context/actions/ConfigActions';

const Layout: React.FC<LayoutI> = ({ children }) => {
    const { state: { error }, dispatch } = useContext();
    const [isModalOpen, setModalOpen] = useState(Boolean(false));
    const [modalContent, setModalContent] = useState("");
    const history = useHistory();
    
     useEffect(() => {
        if (error?.hasError === true) {
            // Scroll to to if there is an error
            window.scrollTo(0, 0);

            if (error?.type === API_ERROR) {
                setModalContent("generic-error-modal");
                setModalOpen(true);
            }
           
            if (error?.type === SESSION_TIMEOUT) {
                dispatch(updateProfile(null));
                return history.push(window.GLOBAL_PATH);
            }

            if (error?.type === VERIFICATION_CODE_EXPIRED) {
                setModalContent("PrintPage.Content.verification-code-expired");
                setModalOpen(true);
            }
        }
        // eslint-disable-next-line 
    }, [error]);

    // handling generic api failure error
    const handleGenericErrorModal = () => {
        setModalOpen(false);
        dispatch(updateError({ hasError: false, type: ""}))
    }

    return (
       
        <div className={"page"}>
            {children}
            
            <ModalComp isModalOpen={isModalOpen} canClose={false}>
                {   parse(i18n.t(modalContent))  }

                <Button 
                    text={i18n.t('try-again')} 
                    onClick={() => handleGenericErrorModal()} 
                />
            </ModalComp>
        </div>
    )
};

export default Layout;
