import React from 'react';
import ReactModal from 'react-modal';
import { ReactComponent as Close } from '../asset/icon-close.svg';
import { ModalI } from './ComponentInterface';

const ModalComp = ({ children, toggleModal, isModalOpen, canClose = true, className }: ModalI) => (
    <ReactModal isOpen={isModalOpen} style={{
        content: {
            border: '0',
            borderRadius: '4px',
            bottom: 'auto',
            minHeight: '10rem',
            left: '50%',
            position: 'fixed',
            right: 'auto',
            top: '50%',
            transform: 'translate(-50%,-50%)',
            minWidth: '20rem',
            width: '80%',
            padding: '0',
            maxHeight: '80vh',
            maxWidth: '640px'
        }
    }} >
        { 
            canClose 
            &&
            <div className="modal-header">
                <button data-testid={"button-close"} onClick={toggleModal}><i>{<Close />}</i></button>
            </div>
        }
        <div className={`modal-container ${className ? className : ''}`}>
            {children}
        </div>

    </ReactModal>
)

export default ModalComp;
