import React from 'react';
import { ButtonI } from '../ComponentInterface';
import {Spinner} from '..';
import { NavLink } from "react-router-dom";

const Button = ({ url, text, disabled, onClick, isLoading, className, dataTestId }: ButtonI) => {
    if(isLoading) {
        return <button data-testid={dataTestId} className={`primary-btn ${className ? className : ''}`}><Spinner /></button>
    }
    if (!onClick) {
        return <NavLink data-testid={dataTestId} className={`primary-btn ${disabled ? 'disabled' : ''} ${className ? className : ''}`} to={url || '/'} tabIndex={0}>{text}</NavLink>
    } else {
        return <button data-testid={dataTestId} className={`primary-btn ${disabled ? 'disabled' : ''} ${className ? className : ''}`} tabIndex={0} onClick={onClick}>{text}</button>
    }
}

export default Button;
