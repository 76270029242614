import React, { useState, useEffect } from 'react';
import { Header, SearchForm, Button, ModalComp, Notification } from "../../components";
import SearchResultTable from "./Components/SearchResultTable";
import i18n from "../../i18n";
import parse from 'html-react-parser';
import { SearchResultI, SearchInputI } from "../../components/ComponentInterface";
import { updateError } from "../../context/actions/ConfigActions";
import { useContext } from "../../context";
import { useTriggeredSearch, useTriggeredFetchVerification, useTriggeredStartVerification, useTriggeredFetchVerificationCode, useTriggeredFetchValidationBrokerData } from "../../services/AdminServices";
import { SESSION_TIMEOUT, API_ERROR, VERIFICATION_CODE_EXPIRED } from "../../utils/Constants";
import { PrintSetupInstructionsRoute } from "../../utils/routes";
import { updatedDataFromValidator } from "../../utils/SearchPage";
import { validateEmail } from '../../components/validate';

const SearchPage = () => {
    const { state: { error }, dispatch } = useContext();
    const [searchData, setSearchData] = useState<SearchInputI>({ rin: "", email: "" });
    
    const { useSearch: searchApi, isLoading } = useTriggeredSearch(searchData);
    const [searchResults, setSearchResults] = useState<Array<SearchResultI>>([]);

    const { useFetchVerification: fetchVerification, isLoading: isLoadingFetchVerification } = useTriggeredFetchVerification(searchResults[0]?.verification_id);
    const { useStartVerification: startVerification, isLoading: isLoadingStartVerification } = useTriggeredStartVerification();
    const { useFetchVerificationCode: fetchVerificationCode, isLoading: isLoadingFetchVerificationCode } = useTriggeredFetchVerificationCode(searchResults[0]?.verification_id);
    const { useFetchValidationBrokerData: fetchValidationBrokerData, isLoading: isLoadingFetchValidationBrokerData } = useTriggeredFetchValidationBrokerData(searchResults[0]?.user_id);
    
    const [validationBrokerData, setValidationBrokerData] =  useState<any>({});
    
    // modal
    const [modalContent, setModalContent] = useState('');
    const [isModalOpen, setModalOpen] = useState(false);

    const [searchingError, setSearchingError] = useState<boolean>(false);
    const [isSearching, setIsSearching] = useState<boolean>(false);

    const [errorObj, setErrorObj] = useState<any>({});

    // Close MOdals on Error
    useEffect(() => {
        if (error?.hasError) {
            setModalOpen(false);
        }

    // eslint-disable-next-line 
    }, [error?.hasError])

     // Handle Searching Api call trigger
     useEffect(() => {
        if (isSearching) {
            handleFetchSearchApi();
        }

    // eslint-disable-next-line 
    }, [isSearching])

    // Update Data from fetched json 
    useEffect(() => {
        const dataFromValidator = () => {
            const result = updatedDataFromValidator(searchResults[0], validationBrokerData);
            // Update the validator fetched data
            const updatedResults = searchResults.map(item => item.user_id === result.user_id ? result : item);

            setSearchResults(updatedResults);
        }
        
        if (Object.keys(validationBrokerData).length) {
            dataFromValidator()
        }

    // eslint-disable-next-line 
    }, [validationBrokerData])
    

    // Search Click
    const handleFetchSearchResults = async () => {
        setSearchingError(false);
        setIsSearching(true);
    
        let formattedData = searchData;

        if (!searchData?.email) {
            formattedData = {
                "rin": searchData?.rin
            }
        } 

        setSearchData(formattedData);
    }

    // Api Call to Fetch the Search Result
    const handleFetchSearchApi= async () => {
        try {            
            const { response, error: api_error } = await searchApi();
        
            if (response?.isSuccess) { 
                setSearchResults(response?.data)
            } 

            if (Object.keys(api_error).length) {  
                if (api_error.isUnauthorizedError || api_error.isForbiddenError) {
                    dispatch(updateError({ hasError: true, type: SESSION_TIMEOUT }));
                } else {
                    dispatch(updateError({ hasError: true, type: API_ERROR }));
                }
            }
        } catch (err) {
            console.log("err", err)
            dispatch(updateError({ hasError: true, type: API_ERROR }));
        } finally {
            setSearchingError(true);
            setIsSearching(false);
        }
    }
    
    // Modal helper MEthods
    const toggleModal = () => setModalOpen(!isModalOpen);

    // PLaceholder account confirmation modal
    const handleCreateAccountModal = () => {
        // Error Cases foe email
        // If email is empty
        if (!searchData?.email) {
            return setErrorObj((prevInputs: any) => ({
                ...prevInputs,
                "email": {
                    hasError: true,
                    errorMessage: 'SearchPage.ErrorMessages.required-field'
                }
            }));
        } else {
            // Valid Email Check
            if (!validateEmail(searchData?.email)) {
                return setErrorObj((prevInputs: any) => ({
                    ...prevInputs,
                    "email": {
                        hasError: true,
                        errorMessage: 'SearchPage.ErrorMessages.invalid-email'
                    }
                }));
            }
        }


        setModalOpen(true);
        setModalContent("SearchPage.confirm-details-modal");
    }

    // Api Call to verfication for UNVERIFIED USER
    // Api Call for Creating a Placeholder Account
    const handleStartVerification = async () => {
        try {
            let formattedData: any = {
                "user_id": searchResults[0]?.user_id,
                "email": searchResults[0]?.email,
                "rin": searchData?.rin,
            };

            // For placeholder Account dont add user_id
            if (!searchResults.length) {
                formattedData = {
                    ...searchData,
                }
            }
           
            const { response, error: api_error } = await startVerification({ data: formattedData });
        
            if (response?.isSuccess) { 
                // Open Link in a new tab
                window.open(response?.data?.link, '_blank');
            } 
    
            if (Object.keys(api_error).length) {  
                if (api_error.isUnauthorizedError || api_error.isForbiddenError) {
                    dispatch(updateError({ hasError: true, type: SESSION_TIMEOUT }));
                } else {
                    dispatch(updateError({ hasError: true, type: API_ERROR }));
                }
            }
        } catch (err) {
            dispatch(updateError({ hasError: true, type: API_ERROR }));
        } finally {
            // Close Placeholder on done
            setModalOpen(false);
        }
    }

    // Api Call to verfication for PENDING REVIEW
    const handleFetchVerification = async () => {
        try {
            const { response, error: api_error } = await fetchVerification();
        
            if (response?.isSuccess) { 
                // Open Link in a new tab
                window.open(response?.data?.link, '_blank');
            } 
    
            if (Object.keys(api_error).length) {  
                if (api_error.isUnauthorizedError || api_error.isForbiddenError) {
                    dispatch(updateError({ hasError: true, type: SESSION_TIMEOUT }));
                } else {
                    dispatch(updateError({ hasError: true, type: API_ERROR }));
                }
            }
        } catch (err) {
            dispatch(updateError({ hasError: true, type: API_ERROR }));
        }
    }

    // Api Call to to FETCH SWAT DATA
    const handleFetchValidationBrokerData = async () => {
        try {
            const { response, error: api_error } = await fetchValidationBrokerData();
        
            if (response?.isSuccess) { 
                setValidationBrokerData(response?.data)
            } 
    
            if (Object.keys(api_error).length) {  
                if (api_error.isUnauthorizedError || api_error.isForbiddenError) {
                    dispatch(updateError({ hasError: true, type: SESSION_TIMEOUT }));
                } else {
                    dispatch(updateError({ hasError: true, type: API_ERROR }));
                }
            }
        } catch (err) {
            dispatch(updateError({ hasError: true, type: API_ERROR }));
        }
    }

    // Api Call to FETCH verification code for printing
    const handleFetchVerificationCode = async () => {
        try {

            const { response, error: api_error } = await fetchVerificationCode();
        
            if (response?.isSuccess) { 
                // Check Code Expiry
                if (response?.data?.expires_at > Date.now()) {
                    // Open Link in a new tab
                    // Add the data in the url params
                    window.open(window.location.origin + window.GLOBAL_PATH + `${PrintSetupInstructionsRoute}?first_name=${response?.data?.first_name}&code=${response?.data?.code}&expiry_date=${response?.data?.expires_at}`, '_blank');
                } else {
                    // Open Code Expired Modal
                    dispatch(updateError({ hasError: true, type: VERIFICATION_CODE_EXPIRED }));
                }
            } 
    
            if (Object.keys(api_error).length) {  
                if (api_error.isUnauthorizedError || api_error.isForbiddenError) {
                    dispatch(updateError({ hasError: true, type: SESSION_TIMEOUT }));
                } else {
                    dispatch(updateError({ hasError: true, type: API_ERROR }));
                }
            }
        } catch (err) {
            dispatch(updateError({ hasError: true, type: API_ERROR }));
        }
    }

    const handleActionableBtns = () => {
        // Unverified Case
        if (searchResults[0]?.verification_status?.toUpperCase() === "UNVERIFIED") {
            return (
                <Button
                    text={i18n.t("SearchPage.SearchLabels.verify-identity-btn")}
                    onClick={() => handleStartVerification()}
                    className='create-account-btn'
                    isLoading={isLoadingStartVerification}
                    dataTestId={"button-primary"}
                />
            )
        }

        // Pending Review Case
        if (searchResults[0]?.verification_status?.toUpperCase() === "PENDING_REVIEW") {
            return (
                <Button
                    text={i18n.t("SearchPage.SearchLabels.verify-identity-btn")}
                    onClick={() => handleFetchVerification()}
                    className='create-account-btn'
                    isLoading={isLoadingFetchVerification}
                    dataTestId={"button-primary"}
                />
            )
        }

         // Verified Case
        if (searchResults[0]?.verification_status?.toUpperCase() === "VERIFIED") {
            return (
                <>
                    {/* {
                        searchResults[0]?.account_status?.toUpperCase() === "ACTIVE"
                        ?
                        <Button
                            text={i18n.t("SearchPage.SearchLabels.verify-healthcard-btn")}
                            onClick={() => {}}
                            className='create-account-btn'
                            dataTestId={"button-verify-hc"}
                        />
                        :
                        null
                    } */}

                    <Button
                        text={i18n.t("SearchPage.SearchLabels.update-data-from-swat-btn")}
                        onClick={() => handleFetchValidationBrokerData()}
                        className='create-account-btn'
                        isLoading={isLoadingFetchValidationBrokerData}
                        dataTestId={"update-from-swat"}
                    />

                    {/* USer Setup Required Case */}

                    {
                        searchResults[0]?.account_status?.toUpperCase() === "PLACEHOLDER"
                        ?
                        <Button
                            text={i18n.t("SearchPage.SearchLabels.print-instructions-btn")}
                            onClick={() => handleFetchVerificationCode()}
                            className='create-account-btn'
                            isLoading={isLoadingFetchVerificationCode}
                            dataTestId={"print-instructions"}
                        />
                        :
                        null
                    }
                </>
            )
        }
    }

    return (
        <>
            <Header 
                title={i18n.t("SearchPage.title")}
                showLogout
            />
            <div className={"searchpage"}>

                <Notification 
                    className="alert-msg" 
                    text={parse(i18n.t("SearchPage.alertMessage"))}
                />

                <section className='search'>
                    <SearchForm 
                        successFunc={handleFetchSearchResults}
                        isLoading={isLoading}
                        isSearchingSetState={setSearchingError}
                        errorObj={errorObj}
                        setErrorObj={setErrorObj}
                        inputs={searchData}
                        setInputs={setSearchData}
                    />
                </section>

                <section className='search-results'>
                    <SearchResultTable
                        data={searchResults}
                        emptyMessage={i18n.t('SearchPage.no-result')}
                        emptyBtnAction={handleCreateAccountModal}
                        searchingError={searchingError}
                        validationBrokerData={validationBrokerData}
                    />

                    {/* Actionable Buttons */}

                    {
                        searchResults?.length
                        ?
                        <div className="btns-container">
                            {
                                handleActionableBtns()
                            }
                        </div>
                        :
                        null
                    }
                </section>
            </div>

            <ModalComp toggleModal={toggleModal} isModalOpen={isModalOpen}>
                <div className='confirm-details-modal' data-testid="confirm-details">
                    {parse(i18n.t(modalContent, { rin: searchData?.rin, email: searchData?.email }))}

                    <Button text={i18n.t('continue')} onClick={handleStartVerification} isLoading={isLoadingStartVerification} dataTestId={"button-primary"} />
                </div>
            </ModalComp>
        </>
    );
}

export default SearchPage;